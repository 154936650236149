import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Card, Spinner } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import {
  LiveEventShopItemConfig,
} from '../../../../../../__gqltypes__';
import { applyChangeIfDateValid } from '../../../../../../utils/datepicker';
import ContentPackListEditor from '../../../../curation/screens/ContentPackList/ContentPackListEditor';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import AssetDisplay from '../../Items/components/AssetDisplay';
import DragUpload from '../../Items/components/DragUpload';
import { MilestoneValue } from '../../Tournament/components/MileStones';
import { RankGroupValue } from '../../Tournament/components/RankGroups';
import {
  GET_LIVE_EVENT,
  REMOVE_LIVE_EVENT,
  UPDATE_LIVE_EVENT,
  UPDATE_LIVE_EVENT_CONTENT_PACKS,
  UPDATE_LIVE_EVENT_SHOP,
} from '../graphql';
import { LiveEventShop } from './LiveEventShop';
import { convertLiveEventShopItemToInputs } from '../utils';

/**
 * A component showing the data of a liveEvent
 */
type Props = {
  liveEventId: string;
  onUpsertLiveEvent: (data: any) => void;
  onRemoveLiveEvent: (data: any) => void;
};

const LiveEventData = ({ liveEventId, onUpsertLiveEvent, onRemoveLiveEvent }: Props) => {
  /**
   * State
   */

  // Currently displayed liveEvent
  /**
   * @type {[LiveEvent, Function]} Currently displayed LiveEvent
   */
  const [name, setName] = useState('');
  
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isDraft, setIsDraft] = useState(false);
  const [isFilterOnlyAdmin, setIsFilterOnlyAdmin] = useState(false);
  const [filterUserIds, setFilterUserIds] = useState<string>('');
  const [readonly, setReadonly] = useState(true);
  const [shop, setShop] = useState<LiveEventShopItemConfig[]>([]);
  
  // pop-up state management ----------
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  
  /**
   * FETCHING
   */
  const {
    data,
    loading: loadingLiveEvent,
    refetch,
  } = useQuery(GET_LIVE_EVENT, {
    fetchPolicy: 'no-cache',
    variables: { liveEventId },
  });

  const [updateLiveEvent, { loading: updateLoading }] = useMutation(UPDATE_LIVE_EVENT, {
    onCompleted: ({ updateLiveEvent: _updateLiveEvent }) => {
      const updatedLiveEvent = _updateLiveEvent.liveEvent;
      onUpsertLiveEvent(updatedLiveEvent);
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateLiveEventContentPacks] = useMutation(UPDATE_LIVE_EVENT_CONTENT_PACKS);

  const [updateLiveEventShop] = useMutation(UPDATE_LIVE_EVENT_SHOP);

  const [removeLiveEvent, { loading: removeLoading }] = useMutation(REMOVE_LIVE_EVENT, {
    onCompleted: ({ removeLiveEvent: _removeLiveEvent }) => {
      if (!_removeLiveEvent.isRemoved) {
        alert("ERROR: Couldn't remove the current liveEvent");
        return;
      }
      onRemoveLiveEvent(liveEventId);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const loading = loadingLiveEvent || updateLoading || removeLoading;

  function userIdsArrayToString(userIdsArray: string[] | string): string {
    if (Array.isArray(userIdsArray)) {
      return userIdsArray.join(', ');
    }
    return userIdsArray;
  }

  function userIdsStringToArray(songIdsString: string): string[] | undefined {
    if (typeof songIdsString === 'string' && songIdsString.trim().length > 0) {
      return songIdsString.split(',').map((id) => id.trim());
    }
    return [];
  }

  /**
   * STATE AUTO UPDATE FUNCTIONS
   */
  // set current liveEvent to the one fetched
  useEffect(() => {
    if (data && data.liveEvent) {
      const fetchedLiveEvent = data.liveEvent;
      setName(fetchedLiveEvent.name);
      setStartDate(new Date(fetchedLiveEvent.startDate));
      setEndDate(new Date(fetchedLiveEvent.endDate));
      setIsDraft(fetchedLiveEvent.isDraft);
      setIsFilterOnlyAdmin(fetchedLiveEvent.filterOnlyAdmin);
      setFilterUserIds(userIdsArrayToString(fetchedLiveEvent.filterUserIds ?? []));
    } else if (!data || !data.liveEvent) {
      setName('');
      setStartDate(null);
      setEndDate(null);
      setIsDraft(false);
      setIsFilterOnlyAdmin(false);
      setFilterUserIds(userIdsArrayToString([]));
    }
  }, [data]);

  useEffect(() => {
    const read = loading;
    setReadonly(read);
  }, [loading, data]);

  /**
   * CHANGES HANDLERS FUNCTIONS
   */
  const handleSave = () => {
    updateLiveEvent({
      variables: {
        input: {
          liveEventId,
          name,
          startDate,
          endDate,
          isDraft,
          filterOnlyAdmin: isFilterOnlyAdmin,
          filterUserIds: userIdsStringToArray(filterUserIds),
        },
      },
    });
  };

  const handleSaveContentPacks = async (contentPackIds: string[]) => {
    await updateLiveEventContentPacks({
      variables: {
        input: {
          liveEventId,
          contentPackIds,
        },
      },
    });
    refetch();
  };

  const handleRemoveLiveEvent = () => {
    removeLiveEvent({
      variables: {
        input: {
          liveEventId,
        },
      },
    });
  };

  const handleChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleChangePlayerIds = useCallback((event) => {
    setFilterUserIds(event.target.value);
  }, []);

  const handleShopUpdate = useCallback((updatedShop: LiveEventShopItemConfig[]) => {
    setShop(updatedShop);
    return shop;
  }, [shop]);

  const handleSaveShop = async () => {
    await updateLiveEventShop({
      variables: {
        input: {
          liveEventId,
          shopItemIds: convertLiveEventShopItemToInputs(shop),
        },
      },
    });
    refetch();
  };

  /**
   * RENDER
   */
  return loading ? (
    <LoadingLogo show />
  ) : (
    <Container className="pb-4">
      <Row className="mb-3">
        <Col md={4}>Start date UTC:</Col>
        <Col md={4}>
          <DateTimePicker
            // For the moment, only 00:00 formats are expects
            dateFormat="YYYY-MM-DD"
            utc={true}
            timeFormat={true}
            timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
            onChange={applyChangeIfDateValid(setStartDate)}
            value={startDate ?? undefined}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            className="mr-2"
            onClick={() => {
              setShowRemoveModal(!showRemoveModal);
            }}
            variant="danger"
            disabled={readonly}
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {readonly || (
            <>
              <Button onClick={handleSave} variant="success">
                Save
              </Button>
            </>
          )}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>End date UTC:</Col>
        <Col md={4}>
          <DateTimePicker
            // For the moment, only 00:00 formats are expects
            dateFormat="YYYY-MM-DD"
            utc={true}
            timeFormat={true}
            timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
            onChange={applyChangeIfDateValid(setEndDate)}
            value={endDate ?? undefined}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}> Name:</Col>
        <Col md={4}>
          <Form.Control disabled={readonly} value={name} onChange={handleChangeName} type="text" />
        </Col>
      </Row>
      
      <Row>
        <Col md={4}>Is Draft</Col>
        <Col md={4}>
          <Form.Check
            type="checkbox"
            checked={isDraft}
            onChange={({ target }) => {
              setIsDraft(target.checked);
            }}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={4}>Filter Admin Only</Col>
        <Col md={4}>
          <Form.Check
            type="checkbox"
            checked={isFilterOnlyAdmin}
            onChange={({ target }) => {
              setIsFilterOnlyAdmin(target.checked);
            }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
          <Col md={3}>
            User Ids: <br />
            (separated by commas &quot;,&quot;)
          </Col>
          <Col md={8}>
            <Form.Control
              as="textarea"
              disabled={readonly}
              value={filterUserIds.toString()}
              onChange={handleChangePlayerIds}
            />
          </Col>
        </Row>
      <hr />
      <Row>
        <Card style={{width: '100%'}}>
          <Card.Header className="text-left">
            <Card.Title>Shop</Card.Title>
            <Button className='align-self-center ml-1' onClick={handleSaveShop}>
              Save shop
            </Button>
          </Card.Header>
          <Card.Body>
            {data && data.liveEvent && (
              // @ts-ignore
              <LiveEventShop 
                shop={data.liveEvent.shop} 
                onUpdateShop={handleShopUpdate}/>
            )}
          </Card.Body>
        </Card>
      </Row>
      <hr />
      {data && data.liveEvent && (
        // @ts-ignore
        <ContentPackListEditor
          contentPacks={data.liveEvent.contentPacks}
          updatePackIds={handleSaveContentPacks}
          enableImport
        />
      )}
      <hr />

      <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stop the current</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to remove the current LiveEvent?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRemoveModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              handleRemoveLiveEvent();
              setShowRemoveModal(false);
            }}
          >
            Remove Live Event
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default LiveEventData;
