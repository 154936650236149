import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Button, Card, Col, Modal, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  LiveEventsQuery,
  LiveEventQuery,
  LiveEvent as LiveEventType,
  RedisCacheModel,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import FlushButton from '../../../devtools/screens/Cache/FlushButton';
import AddLiveEventModal from './components/AddLiveEventModal';
import LiveEventData from './components/LiveEventData';
import { CREATE_LIVE_EVENT, GET_LIVE_EVENTS, GET_LIVE_EVENT } from './graphql';

const baseURL = '/sp3/liveops/live-events';

/**
 * Component/Page to create or edit LiveEvent
 * @returns
 */
const LiveEvent = () => {
  /**
   * GETTING ENV DATA
   */
  const { id: liveEventId }: { id: string } = useParams();
  const history = useHistory();

  /**
   * GRAPHQL
   */

  const {
    data: liveEventsData,
    fetchMore,
    loading: liveEventLoading,
  } = useQuery<LiveEventsQuery>(GET_LIVE_EVENTS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchLiveEvent] = useLazyQuery<LiveEventQuery>(GET_LIVE_EVENT);

  // LiveEvent mutation Logic
  // creating a new liveEvent
  const [addLiveEvent, { loading: addLiveEventLoading }] = useMutation(CREATE_LIVE_EVENT);

  /**
   * STATE
   */
  /**
   * @type {[LiveEvent[], Function]} Loading
   */
  const [liveEvents, setLiveEvents] = React.useState<Partial<LiveEventType>[]>([]);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showStopModal, setShowStopModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [paginationLoading, setPaginationLoading] = React.useState(false);
  const [lastElementRef, setLastElementRef] = React.useState<HTMLDivElement | null>(null);
  /**
   * Helpers function
   */

  const isLiveEventSelected = React.useCallback(() => {
    return !_.isEmpty(liveEventId) && liveEvents.find(({ id }) => id === liveEventId);
  }, [liveEventId, liveEvents]);

  const selectLiveEvent = React.useCallback(
    (newLiveEventId) => {
      history.push(`${baseURL}/${newLiveEventId}`);
    },
    [history]
  );

  // LiveEvent data methods
  /**
   * Update the list of liveEvents after an upsert
   * @param {LiveEvent} quizLiveEvent - the newly created liveEvent
   */
  const handleUpsertLiveEvent = (liveEvent: NonNullable<LiveEventQuery['liveEvent']>) => {
    // Remove the updated liveEvent from the list if it was already in and add it with the updated value
    const otherLiveEvents = liveEvents.filter(({ id }) => id !== liveEvent.id);
    const totalLiveEvents = [...otherLiveEvents, { id: liveEvent.id, startDate: liveEvent.startDate }];
    // Then sort the array
    setLiveEvents(_.orderBy(totalLiveEvents, 'startDate', 'desc'));
  };

  const handleRemoveLiveEvent = (_liveEventId: string) => {
    const remainingLiveEvents = liveEvents.filter(({ id }) => id !== _liveEventId);
    setLiveEvents(_.orderBy(remainingLiveEvents, 'startDate', 'desc'));
  };

  // LiveEvent modal methods
  const handleAdd = (liveEventData: { startDate: Date; endDate: Date }) => {
    setShowAddModal(false);
    addLiveEvent({ variables: { input: liveEventData } })
      .then(({ data: { createLiveEvent } }) => {
        const newLiveEvent = createLiveEvent.liveEvent;
        handleUpsertLiveEvent(newLiveEvent);
        selectLiveEvent(newLiveEvent.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tryToFetchLiveEvent = async () => {
    if (liveEventId) {
      const res = await fetchLiveEvent({ variables: { liveEventId } });
      const liveEvent = res?.data?.liveEvent;

      if (!_.isNil(liveEvent)) {
        const { id, startDate, name } = liveEvent;
        const _updatedLiveEvents = [{ id, startDate, name }, ...liveEvents];
        setLiveEvents(_updatedLiveEvents);
        selectLiveEvent(id);
      } else {
        selectLiveEvent(liveEvents[0].id);
      }
    } else {
      selectLiveEvent(liveEvents[0].id);
    }
  };

  /**
   * STATE UPDATE LOGIC
   */
  React.useEffect(() => {
    if (liveEventId === ':id') {
      selectLiveEvent('');
    }
    if (!isLiveEventSelected() && liveEvents.length > 0) {
      // If liveEventId from url is not yet loaded in the liveEvent list (left menu)
      // We try to add this one to the list
      tryToFetchLiveEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveEventSelected, selectLiveEvent, liveEventId, liveEvents]);

  React.useEffect(() => {
    if (liveEventsData && liveEventsData.liveOps) {
      const _liveEventData = _.orderBy(liveEventsData.liveOps.liveEvents.list, 'startDate', 'desc');
      setLiveEvents(_liveEventData);
    }
  }, [liveEventsData]);

  React.useEffect(() => {
    if (
      liveEventsData &&
      liveEventsData.liveOps &&
      !isLiveEventSelected() &&
      liveEvents.length > 0 &&
      liveEvents.length === liveEventsData.liveOps.liveEvents.list.length
    ) {
      tryToFetchLiveEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveEventSelected, selectLiveEvent, liveEvents, liveEventsData]);

  /**
   * Pagination
   */
  const liveEventsLimit = 20;
  const maxPage = liveEventsData?.liveOps.liveEvents.list
    ? liveEventsData.liveOps.liveEvents.totalCount % liveEventsLimit === 0
      ? Math.floor(liveEventsData.liveOps.liveEvents.totalCount / liveEventsLimit)
      : Math.floor(liveEventsData.liveOps.liveEvents.totalCount / liveEventsLimit) + 1
    : 1;

  const observer = React.useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageCount((no) => no + 1);
      }
    })
  );

  const loadNextPage = async () => {
    setPaginationLoading(true);
    const res = await fetchMore({
      variables: {
        pagination: { offset: liveEventsLimit * (pageCount - 1), limit: liveEventsLimit },
      },
    });
    const data = res.data.liveOps.liveEvents.list;
    const _updatedLiveEvents = _.uniqBy([...liveEvents, ...data], 'id');
    setLiveEvents(_updatedLiveEvents);
    setPaginationLoading(false);
  };

  React.useEffect(() => {
    if (pageCount - 1 <= maxPage && pageCount > 1) {
      loadNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  React.useEffect(() => {
    const currentElement = lastElementRef;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElementRef]);

  /**
   * RENDERING
   */

  return (
    <>
      <LoadingLogo show={liveEventLoading} />
      {isLiveEventSelected() && (
        <Tab.Container activeKey={liveEventId} onSelect={(e) => selectLiveEvent(e)}>
          <Row className="mb-5 h-100">
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
            >
              <Nav.Item className="mb-3">
                {liveEventLoading || addLiveEventLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Button className="w-100" variant="success" onClick={() => setShowAddModal(true)}>
                      <i className="fas fa-calendar-day mr-2" />
                      Schedule a new live event
                    </Button>
                  </>
                )}
              </Nav.Item>

              {/* <Nav.Item className="mb-3">
                <FlushButton model={RedisCacheModel.LIVE_EVENT} />
              </Nav.Item> */}

              <Nav variant="pills">
                {liveEvents.map((liveEvent, index) => (
                  <Nav.Item
                    ref={index === liveEvents.length - 1 && !paginationLoading ? setLastElementRef : null}
                    key={`${liveEvent.id}-${index}date`}
                    className="w-100 text-center position-relative"
                  >
                    <Nav.Link
                      eventKey={liveEvent.id}
                      className={`justify-content-center ${
                        moment(liveEvent.startDate).isBefore(moment())
                          ? 'liveops-nav-link-old'
                          : 'liveops-nav-link-current'
                      }`}
                    >
                      {moment(liveEvent.startDate).format('DD/MM/YYYY')}
                      <br />
                      {moment(liveEvent.startDate).format('LT Z')}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                {paginationLoading && <Spinner animation="border" />}
              </Nav>
            </Col>
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
              className="col-10"
            >
              <Tab.Content className="h-100">
                <Card>
                  <Card.Body>
                    <LiveEventData
                      liveEventId={liveEventId}
                      onUpsertLiveEvent={handleUpsertLiveEvent}
                      onRemoveLiveEvent={handleRemoveLiveEvent}
                    />
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}

      <AddLiveEventModal
        show={(!liveEventLoading && liveEvents.length === 0) || showAddModal}
        loading={addLiveEventLoading}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAdd}
      />
      <Modal show={showStopModal} onHide={() => setShowStopModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stop the current</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to stop the current show?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStopModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowStopModal(false);
            }}
          >
            Stop show
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LiveEvent;
